import { createContext, useCallback, useEffect, useMemo } from "react";

import { Document } from "~/api/types.generated";
import { useCopilotDocumentsLazyQuery } from "~/components/Documents/DocumentsAPI.generated";
import useSelection, { UseSelectionResult } from "~/hooks/useSelection";

export type DocumentObj = Pick<Document, "__typename" | "id" | "name">;

export interface DocumentsFilterContextValue {
  availableDocuments: readonly DocumentObj[];
  selection: UseSelectionResult<DocumentObj>;
}

export const useDocumentsFilter = (
  treeId: string,
  labels: string[]
): DocumentsFilterContextValue => {
  const [loadDocuments, documentsQuery] = useCopilotDocumentsLazyQuery();

  useEffect(() => {
    loadDocuments({ variables: { treeId: treeId, labels: labels } });
  }, [treeId, labels, loadDocuments]);

  const availableDocuments = useMemo(
    () => documentsQuery.data?.documents ?? [],
    [documentsQuery.data?.documents]
  );

  const selection = useSelection(
    availableDocuments,
    useCallback((item) => item.id, []),
    true
  );

  return useMemo(
    () => ({
      availableDocuments,
      selection,
    }),
    [availableDocuments, selection]
  );
};

const DocumentsFilterContext = createContext<DocumentsFilterContextValue>(
  null as any
);

export default DocumentsFilterContext;

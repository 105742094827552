import { useApolloClient } from "@apollo/client";
import { useCallback, useState } from "react";

import { DocumentsCurrent } from "~/components/Documents/useDocumentsCurrent";

import {
  Document_PermissionsFragment,
  Document_PermissionsFragmentDoc,
  useDocumentsUpdatePermissionsMutation,
} from "./DocumentsAPI.generated";

export interface DocumentsPermissions {
  visible: boolean;
  error: string | null;
  loading: boolean;
  document: Document_PermissionsFragment | null;
  canEdit(): boolean;
  edit(documentId: string): void;
  update(documentPermissionId: string, permissible: boolean): Promise<void>;
  close(): void;
}

/** Handles state and logic required for updating document permissions. */
export const useDocumentsPermissions = (
  treeId: string,
  { tree }: DocumentsCurrent
): DocumentsPermissions => {
  const client = useApolloClient();
  const [documentId, setDocumentId] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [request, { loading }] = useDocumentsUpdatePermissionsMutation();
  const visible = documentId !== null;

  const document =
    (documentId &&
      client.readFragment<Document_PermissionsFragment>({
        id: `Document:${documentId}`,
        fragment: Document_PermissionsFragmentDoc,
      })) ||
    null;

  const canEdit = useCallback(() => {
    return tree?.canEditPermissions || false;
  }, [tree?.canEditPermissions]);

  const edit = useCallback((id: string) => setDocumentId(id), []);

  const close = useCallback(() => {
    setDocumentId(null);
    setError(null);
  }, []);

  /** Toggles a single permission */
  const update = useCallback(
    async (documentPermissionId: string, permissible: boolean) => {
      try {
        await request({
          variables: { treeId, documentPermissionId, permissible },
          refetchQueries: ["OAndM"],
        });
      } catch (error) {
        setError("A server error has occurred");
      }
    },
    [request, treeId]
  );

  return {
    visible,
    error,
    loading,
    document,
    canEdit,
    edit,
    update,
    close,
  };
};

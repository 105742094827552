import classnames from "classnames";
import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

/** The ToggleButton component is a button with a huge On/Off switch
 * It also provides a checkbox-like hidden input for integration into forms
 */
const ToggleButton = ({ name, title, value, onClick, loading }) => {
  const [isOn, setIsOn] = useState(false);

  // track the value prop
  useEffect(() => setIsOn(value), [value]);

  return (
    <label
      className={classnames("toggle-button", "label", loading && "disabled")}
      title={title}
    >
      <input
        className="toggle-button input"
        type="checkbox"
        tabIndex="0"
        checked={isOn}
        onChange={() => {
          if (loading) return;
          // eslint-disable-next-line no-unused-expressions
          onClick ? onClick({ name, isOn, setIsOn }) : setIsOn(!isOn);
        }}
      />
      <div
        className={classnames("toggle-button", "slider", isOn ? "on" : "off")}
      >
        <span className="toggle-button ball" />
      </div>
      {isOn ? gettext("On") : gettext("Off")}
    </label>
  );
};

ToggleButton.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.bool,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
};

ToggleButton.defaultProps = {
  name: "",
  title: "",
  value: false,
  onClick: null,
  loading: false,
};

export default ToggleButton;

import { createContext, useEffect, useMemo } from "react";

import { Portfolio } from "~/api/types.generated";
import { usePortfoliosLazyQuery } from "~/components/QAndA/context/QuestionPortfoliosContext.generated";
import { QAndASubjectFragment } from "~/components/QAndA/QAndA.generated";
import useSelection, { UseSelectionResult } from "~/hooks/useSelection";

import { ChildQuestion, ParentQuestion } from "./QuestionContext";

export type PortfolioObj = Pick<Portfolio, "__typename" | "id" | "name">;

export interface PortfolioFilterContextValue {
  availablePortfolios: readonly PortfolioObj[];
  selection: UseSelectionResult<PortfolioObj>;
  filterQuestion: (question: ParentQuestion | ChildQuestion) => boolean;
}

export const usePortfolioFilter = (
  subject: QAndASubjectFragment
): PortfolioFilterContextValue => {
  const [loadPortfolios, portfoliosQuery] = usePortfoliosLazyQuery();

  useEffect(() => {
    if (subject.__typename === "HoldingCompany") {
      loadPortfolios({ variables: { holdingCompanyId: subject.id } });
    }
  }, [subject.__typename, subject.id, loadPortfolios]);

  const availablePortfolios = useMemo(
    () => portfoliosQuery.data?.portfolios ?? [],
    [portfoliosQuery.data?.portfolios]
  );

  // FIXME: Fix the backend types - portfolio.id shouldn't be nullable
  const selection = useSelection(
    availablePortfolios,
    (portfolio) => portfolio.id!
  );

  return useMemo(
    () => ({
      availablePortfolios,
      selection,
      filterQuestion: (question) =>
        !selection.anySelected || selection.includesAny(question.portfolios),
    }),
    [availablePortfolios, selection]
  );
};

const PortfolioFilterContext = createContext<PortfolioFilterContextValue>(
  null as any
);

export default PortfolioFilterContext;

import { keyBy } from "lodash";
import { Key, useCallback, useEffect, useMemo } from "react";
import { useImmer } from "use-immer";

export interface UseSelectionResult<T> {
  count: number;
  anySelected: boolean;
  allSelected: boolean;
  values(): readonly T[];
  select(option: T): void;
  selectAll(): void;
  deselect(option: T): void;
  deselectAll(): void;
  toggle(option: T): void;
  toggleAll(): void;
  includes(option: T): boolean;
  includesAny(options: readonly T[]): boolean;
  includesAll(options: readonly T[]): boolean;
}

const useSelection = <T>(
  options: readonly T[],
  getKey: (option: T) => Key,
  initSelected: boolean = false
): UseSelectionResult<T> => {
  const [selectedKeys, updateSelectedKeys] = useImmer<ReadonlySet<Key>>(
    new Set()
  );

  const optionsByKey = useMemo(() => keyBy(options, getKey), [options, getKey]);

  const count = selectedKeys.size;
  const anySelected = selectedKeys.size > 0;
  const allSelected = selectedKeys.size === options.length;

  const values = useCallback(
    () => [...selectedKeys].map((key) => optionsByKey[key]),
    [selectedKeys, optionsByKey]
  );

  const select = useCallback(
    (option: T) => updateSelectedKeys((keys) => void keys.add(getKey(option))),
    [updateSelectedKeys, getKey]
  );

  const selectAll = useCallback(
    () => updateSelectedKeys(() => new Set(options.map(getKey))),
    [options, updateSelectedKeys, getKey]
  );

  const deselect = useCallback(
    (option: T) =>
      updateSelectedKeys((keys) => void keys.delete(getKey(option))),
    [updateSelectedKeys, getKey]
  );

  const deselectAll = useCallback(
    () => updateSelectedKeys(() => new Set()),
    [updateSelectedKeys]
  );

  const includes = useCallback(
    (option: T) => selectedKeys.has(getKey(option)),
    [selectedKeys, getKey]
  );

  const includesAny = useCallback(
    (options: readonly T[]) => options.some(includes),
    [includes]
  );
  const includesAll = useCallback(
    (options: readonly T[]) => options.every(includes),
    [includes]
  );

  const toggle = useCallback(
    (option: T) => (includes(option) ? deselect(option) : select(option)),
    [includes, deselect, select]
  );

  const toggleAll = useCallback(
    () => (allSelected ? deselectAll() : selectAll()),
    [allSelected, selectAll, deselectAll]
  );

  useEffect(() => {
    if (!initSelected) deselectAll();
    if (initSelected) selectAll();
  }, [options, selectAll, deselectAll, initSelected]);

  return useMemo(
    () => ({
      count,
      anySelected,
      allSelected,
      values,
      select,
      selectAll,
      deselect,
      deselectAll,
      toggle,
      toggleAll,
      includes,
      includesAny,
      includesAll,
    }),
    [
      count,
      anySelected,
      allSelected,
      values,
      select,
      selectAll,
      deselect,
      deselectAll,
      toggle,
      toggleAll,
      includes,
      includesAny,
      includesAll,
    ]
  );
};

export default useSelection;

import clsx from "clsx";
import ToggleButton from "components/forms/ToggleButton";
import Dialog from "components/generic/Dialog";
import { BetterAlert as Alert } from "components/profile/Alert";
import { compact } from "lodash";
import React from "react";

import { PermissionsSetup } from "~/api/types.generated";
import { DocumentsEventTracking } from "~/components/Documents/useDocumentsEventTracking";

import { DocumentsPermissions } from "./useDocumentsPermissions";

export interface DocumentsPermissionsDialogProps extends DocumentsPermissions {
  track: DocumentsEventTracking;
  permissionType?: PermissionsSetup | null;
}

export const DocumentsPermissionsDialog = (
  props: DocumentsPermissionsDialogProps
) => {
  const {
    track,
    document,
    visible,
    error,
    loading,
    update,
    close,
    permissionType,
  } = props;

  return (
    <Dialog
      isOpen={visible}
      onRequestClose={close}
      header={
        <div>
          <span>
            {document && document.fileType === "Folder" ? (
              <i className="thumbnail icon icon--folder" />
            ) : (
              <i className="thumbnail icon icon--file" />
            )}
            <span style={{ paddingLeft: "10px" }}>{document?.name}</span>
          </span>
        </div>
      }
    >
      <div
        className="settings-page mb-5"
        style={{
          maxHeight: "calc(100vh - 210px)",
          overflowY: "auto",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "9px 10px",
            fontSize: "12px",
          }}
        >
          <span>
            {permissionType === "GROUP" ? "Group" : "Role"} Permissions
          </span>
          <span>esc to close</span>
        </div>
        {error && <Alert message={error} classNames={["alert-danger"]} />}
        {compact(document?.permissions).map(
          ({ id, permissible, principal }) => (
            <div
              key={id}
              className={clsx("config-entry", permissible && "_enabled")}
              style={{ fontSize: "14px" }}
            >
              <div
                className="_toggler"
                data-testid="toggle-all-notifications"
                style={{ width: "78px" }}
              >
                <ToggleButton
                  title={principal?.name}
                  value={permissible}
                  onClick={() => {
                    track.permissionsChange(document);
                    update(id, !permissible);
                  }}
                  loading={loading}
                />
              </div>
              <div className="_summary">{principal?.name}</div>
              <div className="_description" />
            </div>
          )
        )}
      </div>
    </Dialog>
  );
};

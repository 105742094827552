import React, { useEffect } from "react";

import { MenuButton, SecondaryButton } from "~/components/common/buttons";
import {
  DownloadIcon,
  FileExcelIcon,
  FileFolderIcon,
  MoreIcon,
  MoveIcon,
  TrashIcon,
} from "~/components/common/icons";
import {
  MenuTooltipContainer,
  useMenuTooltip,
} from "~/components/common/tooltips";
import { DocumentsEventTracking } from "~/components/Documents/useDocumentsEventTracking";

export interface DocumentsTreeMenuProps {
  track: DocumentsEventTracking;
  canEdit: boolean;
  canDelete: boolean;
  canViewAccessLog: boolean;
  accessLogUrl: string | null;
  fileIndexUrl: string | null;
  moveDisabled: boolean;
  removeDisabled: boolean;
  zipDownloadDisabled: boolean;
  createFolder: () => void;
  move: () => void;
  remove: () => void;
  zipDownload: () => void;
}

export const DocumentsTreeMenu = React.memo(function DocumentsTreeMenu(
  props: DocumentsTreeMenuProps
) {
  const {
    track,
    canEdit,
    canDelete,
    canViewAccessLog,
    accessLogUrl,
    fileIndexUrl,
    moveDisabled,
    removeDisabled,
    zipDownloadDisabled,
    createFolder,
    move,
    remove,
    zipDownload,
  } = props;
  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    useMenuTooltip();

  useEffect(() => {
    if (visible) track.treeMenuOpen();
  }, [visible, track]);

  return (
    <>
      <SecondaryButton ref={setTriggerRef} children={<MoreIcon />} />
      {visible && (
        <MenuTooltipContainer ref={setTooltipRef} {...getTooltipProps()}>
          {canEdit && (
            <MenuButton
              onClick={() => {
                track.newFolderClick();
                createFolder();
              }}
            >
              <FileFolderIcon />
              <span>New folder</span>
            </MenuButton>
          )}

          <MenuButton
            onClick={() => {
              track.zipDownloadClick();
              zipDownload();
            }}
            disabled={zipDownloadDisabled}
          >
            <DownloadIcon />
            <span>Export as Zip</span>
          </MenuButton>

          {canEdit && (
            <MenuButton
              onClick={() => {
                track.moveClick();
                move();
              }}
              disabled={moveDisabled}
            >
              <MoveIcon />
              <span>Move</span>
            </MenuButton>
          )}

          {fileIndexUrl && (
            <MenuButton
              as="a"
              target="_blank"
              href={fileIndexUrl}
              onClick={() => {
                track.exportIndexClick();
              }}
            >
              <FileExcelIcon />
              <span>Export index</span>
            </MenuButton>
          )}

          {canViewAccessLog && accessLogUrl && (
            <MenuButton
              as="a"
              target="_blank"
              href={accessLogUrl}
              onClick={() => {
                track.exportAccessLogClick();
              }}
            >
              <FileExcelIcon />
              <span>Export access log</span>
            </MenuButton>
          )}

          {canDelete && (
            <MenuButton
              danger
              onClick={() => {
                track.deleteClick();
                remove();
              }}
              disabled={removeDisabled}
            >
              <TrashIcon />
              <span>Delete (admin)</span>
            </MenuButton>
          )}
        </MenuTooltipContainer>
      )}
    </>
  );
});
